.border-purple {
  border: 1px solid #7D60F5 !important;
}
.border-error {
  border: 1px solid #EB2963 !important;
}

.border-none {
  border: none;
}

.react-phone-number-input {
  background: white;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid rgb(221, 230, 233);
  transition: all 600ms ease 0s;
}

.react-phone-number-input__country .rrui__select__button, .react-phone-number-input__input {
  border-bottom: none !important;
}

.react-phone-number-input.form-control .react-phone-number-input__icon {
  line-height: 0 !important;
}

.react-phone-number-placeholder {
  text-align: left;
  margin-left: 37px;
}
